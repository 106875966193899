<template>
  <div class="media">
    <span class="float-left m-2 mr-4">
      <img
        :src="getImageUrl()"
        style="height: 100px"
        alt
        class="rounded-circle img-thumbnail"
      />
    </span>
    <div class="media-body">
      <h4 :class="`mt-1 mb-1 text-${textColor}`">
        {{ reserveData.equipment_model.brand }} |
        {{ reserveData.equipment_model.name }}
      </h4>
      <p :class="`mt-2 font-19 text-${textColor}`">
        S#
        {{ reserveData.serial_no }}
        | Max TOW : {{ reserveData.max_load }}kg
      </p>
      <ul :class="`mb-0 list-inline text-${textColor}`">
        <li class="list-inline-item mr-3">
          <h5 class="mb-1">
            {{ reserveData.last_packing_date }}
          </h5>
          <p :class="`mb-0 font-13 text-${textColor}-50`">Last Packing Date</p>
        </li>
      </ul>
    </div>
    <!-- end media-body-->
  </div>
  <!-- end media -->
</template>
<script>
export default {
  name: "reserveInfo",
  props: {
    textColor: {
      type: String,
      default: "white",
    },
    reserveData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    getImageUrl() {
      return "/img/default-avatar.jpg";
    },
  },
};
</script>
