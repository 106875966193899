<template>
  <div class="card">
    <div class="card-body">
      <h1 class="header-title mt-0 mb-3">Pilot Information</h1>
      <div class="text-left">
        <p class="text-muted">
          <strong>Serial Number :</strong>
          <span class="ml-2">{{ reserveData.serial_no }}</span>
        </p>
        <p class="text-muted">
          <strong>Equipment :</strong>
          <span class="ml-2"
            >{{ reserveData.equipment_model.brand }} |
            {{ reserveData.equipment_model.name }}
          </span>
        </p>
        <p class="text-muted">
          <strong>Zone :</strong>
          <span class="ml-2">{{ reserveData.operator.zone.name }} </span>
        </p>
        <p class="text-muted">
          <strong>Max TOW Weight :</strong>
          <span class="ml-2">{{ reserveData.max_load }} kg </span>
        </p>
        <p class="text-muted">
          <strong>Zone :</strong>
          <span class="ml-2">{{ reserveData.reserve_weight }} kg </span>
        </p>
        <p class="text-muted">
          <strong>EN Certificate :</strong>
          <span class="ml-2">{{ reserveData.certification }} </span>
        </p>
        <p class="text-muted">
          <strong>Year of Manufacture :</strong>
          <span class="ml-2">{{ reserveData.year_mfg }} </span>
        </p>
        <hr />
        <p class="text-muted">
          <strong>Last Inspected on :</strong>
          <span class="ml-2">{{ reserveData.last_packing_date }} </span>
        </p>
        <p class="text-muted">
          <strong>Last Inspected by :</strong>
          <span class="ml-2">{{ reserveData.last_packed_by }} </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "reserveCard",
  props: {
    textColor: {
      type: String,
      default: "white",
    },
    reserveData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped></style>
